import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { ResponseAssetHistory } from "@/models/interface/asset-history.interface";
import { DownloadRequestParam } from "@/models/interface/download";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ErrorResponseModel } from "@/models/interfaces/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

export class AssetHistoryService extends HttpClient {
  public listAssetHistoryReport(
    params: RequestQueryParamsModel
  ): Promise<Pagination<ResponseAssetHistory>> {
    return this.get<Pagination<ResponseAssetHistory>>(Api.AssetHistoryReport, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public downloadListAssetHistoryReport(
    params: DownloadRequestParam
  ): Promise<Blob> {
    return this.get<Blob>(Api.AssetHistoryReportDownload, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const assetHistoryService = new AssetHistoryService();
