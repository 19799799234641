






























































































import { SearchBuilder } from "@/builder";
import SelectUnitCode from "@/components/custom/select/SelectUnitCode.vue";
import dateFormat from "@/filters/date.filter";
import { toTitlecase } from "@/helpers/common";
import { APagination, useBlob } from "@/hooks";
import { AssetHistoryMapper } from "@/mapper/AssetHistory.mapper";
import MNotification from "@/mixins/MNotification.vue";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DATE_FORMAT_DD_MM_YYYY_HH_MM_SS } from "@/models/constants/date.constant";
import {
  ResponseAssetHistory,
  ResponseAssetHistoryTransactionDetail,
} from "@/models/interface/asset-history.interface";
import { assetAdjustmentService } from "@/services/asset-adjustment.service";
import { assetHistoryService } from "@/services/asset-history.service";
import { assetsServices } from "@/services/assets.service";
import { LabelInValue } from "@/types";
import { StringUtils } from "@/utils";
import { FormModel } from "ant-design-vue";
import { Component, Ref } from "vue-property-decorator";

interface Filter {
  unitCode?: LabelInValue;
}

interface Journal {
  id: string;
  number: string;
}

interface ResponseAssetHistoryRow extends ResponseAssetHistory {
  transactionDetailString: string;
  journalObjects: Journal[];
}

@Component({
  components: {
    SelectUnitCode,
  },
})
export default class AssetHistory extends MNotification {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  @Ref("formModel")
  formModel!: FormModel;

  form: Filter = {
    unitCode: undefined,
  };
  formRules = {
    unitCode: [
      { required: true, message: this.$t("lbl_validation_required_error") },
    ],
  };

  loading = {
    find: false,
    download: false,
  };
  pagination = {
    page: DEFAULT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
  };

  columns = [
    {
      title: this.$t("lbl_reference_number"),
      dataIndex: "referenceNo",
      key: "referenceNo",
      scopedSlots: { customRender: "navigate" },
    },
    {
      title: this.$t("lbl_book_type"),
      dataIndex: "bookType",
      key: "bookType",
      customRender: (text: string) => (text ? toTitlecase(text) : "-"),
    },
    {
      title: this.$t("lbl_transaction_type"),
      dataIndex: "transactionType",
      key: "transactionType",
      scopedSlots: { customRender: "navigate" },
    },
    {
      title: this.$t("lbl_journal_number"),
      dataIndex: "journalNumbers",
      key: "journalNumbers",
      scopedSlots: { customRender: "journalNumbers" },
    },
    {
      title: this.$t("lbl_asset_number"),
      dataIndex: "assetNo",
      key: "assetNo",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_transaction_date"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      customRender: (text: string) =>
        dateFormat(text, DATE_FORMAT_DD_MM_YYYY_HH_MM_SS),
    },
    {
      title: this.$t("lbl_period"),
      dataIndex: "period",
      key: "period",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_transaction_details"),
      dataIndex: "transactionDetailString",
      key: "transactionDetailString",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_unit_code_parent"),
      dataIndex: "parentUnitCode",
      key: "parentUnitCode",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_created_by"),
      dataIndex: "createdBy",
      key: "createdBy",
      customRender: (text: string) => text || "-",
    },
    {
      title: this.$t("lbl_created_date"),
      dataIndex: "createdDate",
      key: "createdDate",
      customRender: (text: string) =>
        dateFormat(text, DATE_FORMAT_DD_MM_YYYY_HH_MM_SS),
    },
  ];

  data: Pagination<ResponseAssetHistoryRow> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  onSearch(): void {
    this.formModel.validate((valid: boolean) => {
      if (!valid) {
        return;
      }

      this.fetchData(this.buildQueryParams());
    });
  }

  onDownloadClick(): void {
    const { toDownload } = useBlob();

    if (!this.form.unitCode || !this.form.unitCode.label) {
      this.showNotifError("notif_empty_mandatory_fields");
      return;
    }

    this.loading.download = true;
    const downloadParams = AssetHistoryMapper.mapDownloadParams(
      this.buildQueryParams(),
      this.form.unitCode?.label
    );
    assetHistoryService
      .downloadListAssetHistoryReport(downloadParams)
      .then(res => {
        toDownload(res, "asset-history.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  onFilterReset(): void {
    this.form.unitCode = undefined;
  }

  buildQueryParams(): RequestQueryParams {
    const builder = new SearchBuilder();
    const params = new RequestQueryParams();
    params.limit = this.pagination.limit;
    params.page = this.pagination.page - ONE;

    if (this.form.unitCode?.label) {
      params.search = builder
        .push(["unitCode", this.form.unitCode.label])
        .build();
    }

    return params;
  }

  onChangeTable(pagination: APagination): void {
    const { current, pageSize } = pagination;

    this.pagination.page =
      pageSize !== this.pagination.limit ? DEFAULT_PAGE : current;
    this.pagination.limit = pageSize;

    this.fetchData(this.buildQueryParams());
  }

  fetchData(params: RequestQueryParams = new RequestQueryParams()): void {
    this.loading.find = true;
    assetHistoryService
      .listAssetHistoryReport(params)
      .then(res => {
        this.data = {
          ...res,
          data: res.data.map(
            this.convertResponseAssetHistoryToResponseAssetHistoryRow
          ),
        };
      })
      .finally(() => (this.loading.find = false));
  }

  async navigateByTransactionType(
    record: ResponseAssetHistoryRow
  ): Promise<void> {
    const builder = new SearchBuilder();

    const params = new RequestQueryParams();
    params.search = builder.push(["referenceNo", record.referenceNo]).build();
    const routeParamsRefNo = encodeURIComponent(record.referenceNo);
    const transactionType = record.transactionType.toUpperCase();

    if (transactionType === "ADDITION") {
      const data = await assetsServices.listAssetAdditions(params);
      if (data.data.length > 0 && routeParamsRefNo) {
        this.$router.push({
          name: "asset.view.additions.single",
          params: {
            id: routeParamsRefNo,
          },
        });
      }
    } else if (transactionType === "ADJUSTMENT") {
      const data = await assetAdjustmentService.detailAssetAdjustment(params);
      if (data.length > 0 && routeParamsRefNo) {
        this.$router.push({
          name: "asset.inquiry.adjustment",
          params: {
            id: routeParamsRefNo,
          },
        });
      }
    } else if (transactionType === "RETIREMENT") {
      const paramsRetirement = new RequestQueryParams();
      paramsRetirement.search = builder
        .push(["documentNumber", record.referenceNo])
        .build();
      paramsRetirement.sorts = "createdDate:desc";

      const data = await assetsServices.detailAssetRetirement(
        "",
        paramsRetirement
      );
      if (data.data.length > 0 && routeParamsRefNo) {
        this.$router.push({
          name: "asset.inquiry.retirement",
          params: {
            id: routeParamsRefNo,
          },
        });
      }
    }
  }

  //#region utilities
  convertTransactionDetailToString(
    detail: ResponseAssetHistoryTransactionDetail
  ): string {
    const transactionDetail =
      Object.keys(detail.oldData).length > 0 ? detail.oldData : detail.newData;
    return StringUtils.objectToString(
      transactionDetail as Record<string, unknown>,
      ". "
    );
  }

  convertJournalToJournalObjects(journalsString?: string): Journal[] {
    const JOURNALS_DELIMITOR = " || ";
    const JOURNAL_DELIMITOR = ";";
    const JOURNAL_ID_INDEX = 0;
    const JOURNAL_NUMBER_INDEX = 1;

    const journals = journalsString?.split(JOURNALS_DELIMITOR) || [];

    return journals.map(journal => {
      const idAndNumber = journal.split(JOURNAL_DELIMITOR);
      return {
        id: idAndNumber[JOURNAL_ID_INDEX],
        number: idAndNumber[JOURNAL_NUMBER_INDEX],
      };
    });
  }

  convertResponseAssetHistoryToResponseAssetHistoryRow(
    responseAssetHistory: ResponseAssetHistory
  ): ResponseAssetHistoryRow {
    const transactionDetailString = this.convertTransactionDetailToString(
      responseAssetHistory.transactionDetail
    );
    const journalObjects: Journal[] = this.convertJournalToJournalObjects(
      responseAssetHistory.journals
    );

    return {
      ...responseAssetHistory,
      transactionType: toTitlecase(responseAssetHistory.transactionType),
      transactionDetailString,
      journalObjects,
    };
  }
  //#endregion utilities
}
