var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_asset_history_report") } },
    [
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-form-model",
                {
                  ref: "formModel",
                  attrs: {
                    rules: _vm.formRules,
                    model: _vm.form,
                    "data-testid": "asset-history-form"
                  },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.onSearch.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_unit_code"),
                                prop: "unitCode"
                              }
                            },
                            [
                              _c("SelectUnitCode", {
                                attrs: { "label-in-value": "" },
                                model: {
                                  value: _vm.form.unitCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "unitCode", $$v)
                                  },
                                  expression: "form.unitCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    "data-testid": "asset-history-btn-reset"
                                  },
                                  on: { click: _vm.onFilterReset }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_reset")))]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    "html-type": "submit",
                                    "data-testid": "asset-history-btn-find"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_find")) + " ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  dataSource: _vm.data.data,
                  columns: _vm.columns,
                  "row-key": "id",
                  loading: _vm.loading.find,
                  scroll: { x: 3000 },
                  pagination: {
                    showTotal: function() {
                      return _vm.$t("lbl_total_items", {
                        total: _vm.data.totalElements
                      })
                    },
                    total: _vm.data.totalElements,
                    showSizeChanger: true,
                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                    current: _vm.pagination.page,
                    defaultPageSize: _vm.pagination.limit
                  },
                  "data-testid": "asset-history-table"
                },
                on: { change: _vm.onChangeTable },
                scopedSlots: _vm._u([
                  {
                    key: "journalNumbers",
                    fn: function(_, record) {
                      return [
                        _vm._l(record.journalObjects, function(journal) {
                          return [
                            _c(
                              "div",
                              { key: journal.id },
                              [
                                _c(
                                  "RouterLink",
                                  {
                                    attrs: {
                                      to: {
                                        name: "generaljournal.detail",
                                        params: {
                                          id: journal.id
                                        }
                                      },
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(" " + _vm._s(journal.number) + " ")]
                                )
                              ],
                              1
                            )
                          ]
                        }),
                        !record.journalObjects.length
                          ? _c("span", [_vm._v(_vm._s("-"))])
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "navigate",
                    fn: function(text, record) {
                      return [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: {
                              click: function($event) {
                                return _vm.navigateByTransactionType(record)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(text) + " ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    loading: _vm.loading.download,
                    "data-testid": "asset-history-btn-download"
                  },
                  on: { click: _vm.onDownloadClick }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }