import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DownloadRequestParam } from "@/models/interface/download";
import store from "@/store";

export class AssetHistoryMapper {
  static mapDownloadParams(
    params: RequestQueryParams,
    unitCode?: string
  ): DownloadRequestParam {
    const company = store.state["authStore"].authData.companyName || "";

    const arrayParams = [company, unitCode || ""];

    return {
      ...params,
      params: arrayParams.join(","),
    };
  }
}
